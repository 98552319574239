const PlanDetailsView = () =>
  import(
    /*webpackChunkName: "PlanDetailsLoggedView"*/ "../../../views/sales/PlanDetailsView.vue"
  );
export default {
  path: "plans-logged",
  name: "plans-logged",
  component: PlanDetailsView,
  meta: {
    requiresAuth: true,
    requiresEntitlement: false,
  },
};

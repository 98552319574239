import { createRouter, createWebHistory } from "vue-router";
import { UserStore } from "../stores/userStore";
// @ts-ignore
import { ProfileStore } from "@/stores/profileStore";
// import { routes } from "./routes";
// import { appRoutes } from "./appRoutes";
import axios from "axios";
// @ts-ignore
import { i18n } from "@/functional/languages";

// @ts-ignore
import Redirections from "@/router/routes/redirections";

// @ts-ignore
import Trends from "@/router/routes/trends";

// const combinedRoutes = [...routes, ...appRoutes];

import routes from "./routes/index";

// @ts-ignore
import { FlixlatinoApiClient } from "@/services/FlixlatinoApiClient";

const allRoutes = [
  ...Trends,
  ...Redirections,
  {
    path: "/:locale?",
    children: routes,
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: allRoutes,
});

const scrollToTop = () => {
  const c = document.documentElement.scrollTop || document.body.scrollTop;
  if (c > 0) {
    window.scrollTo(0, 0);
  }
};

const setAxiosToken = (token: string) => {
  if (!axios.defaults.params) {
    axios.defaults.params = {};
  }
  axios.defaults.params["token"] = token;
};

router.afterEach(() => {
  scrollToTop();
});
router.beforeEach((to, from, next) => {
  const userStore = UserStore();
  const profileStore = ProfileStore();
  const apiClient = new FlixlatinoApiClient();

  const setDefaultProfile = async () => {
    apiClient.getProfiles().then((response: any) => {
      if (response) {
        if (userStore.trialEntitlement) {
          profileStore.setProfiles(response.profiles.all);
          apiClient
              .getInitData(profileStore.getProfiles[0], userStore.getLanguage)
              .then(() => {
                profileStore.setCurrentProfile(profileStore.getProfiles[0]);
              });
        }
      }
    });
  };

  if (to.params.locale === "" || to.params.locale === null) {
    to.params.locale = userStore.getLanguage;
  } else if (to.params.locale === "es" || to.params.locale === "en") {
    userStore.setLanguage(to.params.locale);
  }

  if (to.name === "country-movies" && to.path.includes("country-movies")) {
    if (to.params.locale === "es") {
      next({
        path: `/es/descubre/${to.params.country}`,
      });
    } else {
      next({
        path: `/en/discover/${to.params.country}`,
      });
    }
  }

  i18n.locale = to.params.locale;
  axios.defaults.params["lang"] = to.params.locale;

  if (
    to.query["l"] !== undefined &&
    to.query["l"] !== null &&
    to.query["l"] === "reset_password"
  ) {
    const resetPasswordPath =
      to.query["lang"] === "en" ? "/reset-password/" : "/reiniciar-contrasena/";
    next({
      path:
        to.query["lang"] +
        resetPasswordPath +
        to.query["q"] +
        "/" +
        to.query["email"],
    });
    return;
  }

  if (to.fullPath.toLowerCase() !== to.fullPath) {
    next({ path: to.fullPath.toLowerCase() });
  }

  console.info("User is entitled:", userStore.isEntitled);
  console.info("User is logged in:", userStore.isLoggedIn);
  console.info("User is trial in:", userStore.trialEntitlement);
  console.info("route:", to.name);

  if (typeof to.meta.lang !== "undefined") {
    axios.defaults.params = {};
    axios.defaults.params["lang"] = to.meta.lang;
  }

  if (typeof userStore.isEntitled === "undefined") {
    setAxiosToken(userStore.getToken);
  }

  if (
    userStore.isLoggedIn &&
    userStore.trialEntitlement &&
    !profileStore.isProfileSelected &&
    !["live", "plans-logged", "checkout"].includes(<string>to.name)
  ) {
    setDefaultProfile();
    next({ name: "live" });
    return;
  }

  if (
    !profileStore.isProfileSelected &&
    userStore.isLoggedIn &&
    !userStore.trialEntitlement &&
    !["profiles", "link", "plans-logged", "checkout"].includes(<string>to.name)
  ) {
    next({ name: "profiles" });
    return;
  }

  if (
    to.matched.some((record) => record.meta.requiresAuth) &&
    !userStore.isLoggedIn
  ) {
    //console.info("User is not logged in, redirecting to login page", from, to);

    if (to.name === "checkout") {
      next({ name: "register" });
    } else {
      userStore.setEntrypoint(to.fullPath);
      if (to.fullPath.includes("to_register")) {
        next({ name: "register" });
      } else {
        next({ name: "login" });
      }
    }
    return;
  } else if (
    userStore.isLoggedIn &&
    !to.matched.some((record) => record.meta.requiresAuth) &&
    !to.matched.some((record) => record.meta.isAmbiguous)
  ) {
    //console.info("This is a public route, user should not be here:", to.name);
    next({ name: "home-app" });
    return;
  }

  next();
});

export default router;
